import { ChainId } from '@uniswap/sdk'
import { Unisat } from './Unisat'
import elliptic from 'elliptic'
import { ethers } from 'ethers'
import * as bitcoin from 'bitcoinjs-lib'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { RLP, arrayify, concat, hexlify, stripZeros } from 'ethers/lib/utils'

const ec = new elliptic.ec('secp256k1')
export const OVERLAY_READY = 'OVERLAY_READY'
// const CHAIN_ID_NETWORK_ARGUMENT: { readonly [chainId in FormaticSupportedChains]: string | undefined } = {
//   [ChainId.MAINNET]: undefined,
//   [ChainId.ROPSTEN]: 'ropsten',
//   [ChainId.RINKEBY]: 'rinkeby',
//   [ChainId.KOVAN]: 'kovan'
// }
export const numberToHex = (raw: string) => {
  const options = { hexPad: 'left' }
  let value = arrayify(hexlify(raw, options as any))
  value = stripZeros(value)
  return hexlify(value)
}
export const serializeTransaction = (tx: any, signature: any = undefined) => {
  const { chainId, gas, nonce, to, value, gasPrice, data } = tx

  const serializedTransaction = [
    numberToHex(chainId!),
    nonce ? numberToHex(nonce) : '0x',
    gasPrice ? numberToHex(gasPrice) : '0x',
    gas ? numberToHex(gas) : '0x',
    to ?? '0x',
    value ? numberToHex(value) : '0x',
    data ?? '0x',
    []
  ]

  if (signature) {
    serializedTransaction.push(
      hexlify(signature.v),
      stripZeros(arrayify(signature.r)),
      stripZeros(arrayify(signature.s))
    )
  }

  return hexlify(concat(['0x04', RLP.encode(serializedTransaction)]))
}

export interface ConnectorUpdate<T = number | string> {
  provider?: any
  chainId?: T
  account?: null | string
}

export function isValidBitcoinAddress(address: string) {
  try {
    bitcoin.address.fromBech32(address)
    return true
  } catch (e) {
    return false
  }
}

export async function getEVMAddress(address: string) {
  if (isValidBitcoinAddress(address)) {
    console.log('UnisatWrapper.request', 'fetching btc address')
    return await fetch(process.env.NEXT_PUBLIC_ADDRESS_HUB_URL + '/queryAddress?address=' + address, {
      method: 'GET'
    })
      .then(data => {
        return data.json().then(result => {
          return result.evmAddress
        })
      })
      .catch(e => {
        console.error(e)
        throw e
      })
  }
  return address
}

const AddressHubUrl = process.env.REACT_APP_ADDRESS_HUB_URL

export function evmAddressToBech32(e: string, network: 'testnet' | 'livenet' = 'testnet') {
  return bitcoin.address.toBech32(Buffer.from(e.slice(2), 'hex'), 0, network == 'testnet' ? 'tb' : 'bc')
}

export function bech32ToEvmAddress(address: string) {
  const a = bitcoin.address.fromBech32(address)
  return `0x${a.data.toString('hex')}`
}

export function tryConvertBtcAddress(address: string) {
  try {
    return address === '' ? '' : evmAddressToBech32(address)
  } catch (e) {
    return ''
  }
}

export function tryConvertEvmAddress(btcAddress: string) {
  try {
    return btcAddress === '' ? '' : bech32ToEvmAddress(btcAddress)
  } catch (e) {
    return ''
  }
}

export function getEvmAddress(address: string) {
  if (address.startsWith('0x')) {
    return address
  }
  try {
    return bech32ToEvmAddress(address)
  } catch (e) {
    return ''
  }
}

export class UnisatConnector extends AbstractConnector {
  pubkey = ''
  btcAddress = ''
  evmAddress = ''
  supportChainIds: any
  evmProvider

  constructor({ supportChainIds, url }: { supportChainIds: any; url: string }) {
    super()
    this.supportChainIds = supportChainIds
    this.evmProvider = new ethers.providers.StaticJsonRpcProvider(url, 28206)
  }

  close() {

  }

  async updatePubkey(accounts: string[]) {
    this.pubkey = await (await this.getUnisatProvider()).getPublicKey()
    this.btcAddress = accounts[0]
    this.evmAddress = bech32ToEvmAddress(this.btcAddress)
    console.log('evmAddress:', this.evmAddress)

    // await fetch(AddressHubUrl + '/submitPublicKey', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     publicKey: this.pubkey
    //   })
    // }).catch(e => {
    //   console.error('Failed to submit public key', e)
    // })

    return this.evmAddress
  }

  async activate() {
    console.log('UnisatWrapper.activate')
    try {
      const provider = await this.getUnisatProvider()
      const chainId = await provider.getNetwork()
      if (chainId == 'livenet') {
        await provider.switchNetwork('testnet')
      }
      await provider.requestAccounts()
      const account = await this.getAccount()
      console.log('UnisatWrapper.activate acc', account)

      // if (account.length === 0 || (account as string) === '') {
      //   try {
      //     account = await this.getAccount()
      //   } catch (error) {
      //     throw error
      //   }
      // }

      provider.on('accountsChanged', this.onAccountsChanged.bind(this))
      provider.on('networkChanged', this.onChainChanged.bind(this))
      provider.on('disconnect', this.onDisconnect.bind(this))

      const currentChainId = await this.getChainId()

      return { account, chainId: currentChainId, provider: await this.getProvider() }
    } catch (error) {
      throw error
    }
  }

  getUnisatProvider() {
    return (window as any).unisat as Unisat
  }

  async getProvider() {
    const handler = {
      get(target: { [x: string]: any }, propKey: string | number, receiver: unknown) {
        const origMethod = target[propKey]
        if (typeof origMethod === 'function') {
          return (...args: any) => {
            console.log(`Calling ${String(propKey)} with arguments:`, args)
            const result = origMethod.apply(this, ...args)
            if (result instanceof Promise) {
              return result.then(res => {
                console.log(`Result of ${String(propKey)}:`, res)
                return res
              })
            }
            console.log(`Result of ${String(propKey)}:`, result)
            return result
          }
        }
        return Reflect.get(target, propKey, receiver)
      }
    }

    return async (method: string, params: any[] = []) => {
      console.log('UnisatWrapper.getProvider', method, params)
      switch (method) {
        case 'eth_sendTransaction':
          const transaction: any = params[0]
          const tx = {
            to: transaction.to,
            nonce:
              transaction.nonce ??
              (await this.evmProvider.send('eth_getTransactionCount', [this.btcAddress, 'pending'])),
            data: transaction.data ?? '0x',
            value: transaction.value ?? 0,
            gas: transaction.gas ?? 0,
            gasPrice: transaction.gasPrice ?? (await this.evmProvider.getGasPrice()),
            chainId: transaction.chainId ?? (await this.getChainId())
          }
          console.log('UnisatWrapper.getProvider', 'tx', tx)

          const rawTx = serializeTransaction(tx)
          const provider = this.getUnisatProvider()
          const signed: string = Buffer.from(
            await provider.signMessage(ethers.utils.keccak256(rawTx), 'ecdsa'),
            'base64'
          ).toString('hex')

          const sign = {
            r: `0x${signed.slice(2, 66)}`,
            s: `0x${signed.slice(66, 130)}`,
            v: parseInt(`0x${signed.slice(0, 2)}`, 16) - 4
          }
          const typedRaw = serializeTransaction(tx, sign)

          return await this.evmProvider.send('eth_sendRawTransaction', [typedRaw])
        case 'eth_getTransactionReceipt':
          const a = await this.evmProvider.send(method, params)
          if (a.from) {
            a.from = bech32ToEvmAddress(a.from)
          }
          if (a.to) {
            a.to = bech32ToEvmAddress(a.to)
          }
          return a
        case 'eth_call':
        case 'eth_estimateGas':
          try {
            params[0].to = evmAddressToBech32(params[0].to)
            if (params[0].from) {
              params[0].from = evmAddressToBech32(params[0].from)
            }
          } catch (e) {
            console.log(e)
          }
          return await this.evmProvider.send(method, params)
        default:
          return await this.evmProvider.send(method, params)
      }
    }
  } //: Promise<any>

  async getChainId() {
    const provider = await this.getUnisatProvider()
    const chainId = await provider.getNetwork()
    return chainId == 'livenet' ? 28205 : 28206
  }

  async getAccount() {
    const provider = await this.getUnisatProvider()
    const accounts = await provider!.getAccounts()
    if (this.btcAddress != accounts[0]) await this.updatePubkey(accounts as any)

    return this.evmAddress
  }

  async deactivate() {
    this.onDeactivation()
  }

  async isAuthorized() {
    try {
      return (await this.getAccount()).length > 0
    } catch {
      return false
    }
  } //: Promise<boolean>
  async onDeactivation() {
    const provider = await this.getUnisatProvider()
    provider.removeListener('accountsChanged', this.onAccountsChanged.bind(this))
    provider.removeListener('networkChanged', this.onChainChanged.bind(this))
    provider.removeListener('disconnect', this.onDisconnect.bind(this))
  }

  public onAccountsChanged(accounts: string[]) {
    console.log('UnisatWrapper.onAccountsChanged', accounts)
    // this.updatePubkey(accounts as any).then((acc) => {
    //   config.emitter.emit('change', {
    //     accounts: ([acc] as `0x${string}`[])
    //   })
    // })
  }

  public onChainChanged(chain: any) {
    console.log('UnisatWrapper.onChainChanged', chain)
  }

  async onDisconnect(_error: any) {
    const provider = await this.getUnisatProvider()
    provider.removeListener('accountsChanged', this.onAccountsChanged)
    provider.removeListener('chainChanged', this.onChainChanged)
    provider.removeListener('disconnect', this.onDisconnect.bind(this))
  }
}
