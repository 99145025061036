import { TokenAmount, Pair, Currency, Token, INIT_CODE_HASH } from '@uniswap/sdk'
import { useMemo } from 'react'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { Interface } from '@ethersproject/abi'
import { useActiveWeb3React } from '../hooks'

import { useMultipleContractSingleData } from '../state/multicall/hooks'
import { wrappedCurrency } from '../utils/wrappedCurrency'
import { getCreate2Address, keccak256, solidityKeccak256, solidityPack } from 'ethers/lib/utils'

const PAIR_INTERFACE = new Interface(IUniswapV2PairABI)

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID
}
const FACTORY_ADDRESS = '0xF6F76f4E65367c2318adD441FDEB5fC7C2143322'

const PAIR_ADDRESS_CACHE = {} as { [key: string]: { [key: string]: string } | undefined }
export function getAddress(tokenA: Token, tokenB: Token) {
  const tokens = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA] // does safety checks
  if (
    PAIR_ADDRESS_CACHE[tokens[0].address] === undefined ||
    PAIR_ADDRESS_CACHE[tokens[0].address]![tokens[1].address] === undefined
  ) {
    if (PAIR_ADDRESS_CACHE[tokens[0].address] === undefined) {
      PAIR_ADDRESS_CACHE[tokens[0].address] = {}
    }

    PAIR_ADDRESS_CACHE[tokens[0].address]![tokens[1].address] = getCreate2Address(
      FACTORY_ADDRESS,
      solidityKeccak256(['bytes'], [solidityPack(['address', 'address'], [tokens[0].address, tokens[1].address])]),
      "0x2cf69d7e5714f583a83b97e41cd6acfc7cc158f8b7761490999da2dd79842e6f"
    )
  }

  return PAIR_ADDRESS_CACHE[tokens[0].address]![tokens[1].address]
}

export function usePairs(currencies: [Currency | undefined, Currency | undefined][]): [PairState, Pair | null][] {
  const { chainId } = useActiveWeb3React()

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId)
      ]),
    [chainId, currencies]
  )

  const pairAddresses = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        return tokenA && tokenB && !tokenA.equals(tokenB) ? getAddress(tokenA, tokenB) : undefined
      }),
    [tokens]
  )

  const results = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'getReserves')

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PairState.EXISTS,
        new Pair(new TokenAmount(token0, reserve0.toString()), new TokenAmount(token1, reserve1.toString()))
      ]
    })
  }, [results, tokens])
}

export function usePair(tokenA?: Currency, tokenB?: Currency): [PairState, Pair | null] {
  return usePairs([[tokenA, tokenB]])[0]
}
